import { SolutionOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import CardCover from "../../assets/green tree-150-01.jpg";
import Wrapper from "../../components/wrapper/Wrapper";

const { Title, Text } = Typography;
const { Meta } = Card;

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row>
          <Col xs={24} className="text-center">
            <Title level={2}>EVIDENCIJA LOKACIJA ODBAČENOG OTPADA</Title>
          </Col>
        </Row>

        <Row style={{ marginBottom: "0.5rem" }}>
          <Col xs={24} className="text-center">
            <Text>Zaštitimo prirodu zajedno - protiv ilegalnih deponija</Text>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              className="card"
              hoverable
              cover={<img alt="example" src={CardCover} />}
            >
              <Meta
                title="1. ODABIR LOKACIJE"
                description="Koristite dva prsta na karti za odabir lokacije otpada. Možete pomicati prste gore-dolje i lijevo-desno te zumirati kako biste precizno postavili lokaciju."
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              className="card"
              hoverable
              cover={<img alt="example" src={CardCover} />}
            >
              <Meta
                title="2. PRILOŽITE SLIKU"
                description="Nakon odabira lokacije, trebate unijeti određene informacije, priložiti fotografiju, a zatim kliknuti na dugme Pošalji."
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              className="card"
              hoverable
              cover={<img alt="example" src={CardCover} />}
            >
              <Meta
                title="3. PROVJERA PRIJAVE"
                description="Nakon uspješnog popunjavanja polja i slanja prijave, sljedeći korak je da administracija razmotri prijavu i posjeti lokaciju kako bi provjerila je li zaista riječ o deponiji. Ako je prijava točna, deponija će biti dodana u registar."
              />
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: "3rem" }}>
          <Col xs={24} className="text-center">
            <Link to="/registracija">
              <Button
                type="primary"
                size="large"
                icon={<SolutionOutlined />}
                style={{ background: "#00B96B" }}
              >
                Prijavite otpad
              </Button>
            </Link>
          </Col>
        </Row>
      </Space>
    </Wrapper>
  );
};

export default Home;
