import { Button, Form, FormInstance } from "antd";
import React, { useState, useEffect } from "react";

type Props = {
  form: FormInstance;
  loading: boolean;
};
const SubmitButton: React.FC<Props> = ({ form, loading }) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <Button
      type="primary"
      htmlType="submit"
      loading={loading}
      disabled={!submittable || loading}
    >
      Pošalji
    </Button>
  );
};

export default SubmitButton;
