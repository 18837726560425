import { Col, Row } from "antd";
import React from "react";

type Props = {
  children: React.ReactNode;
};
const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <Row>
      <Col xs={2} md={3} />
      <Col xs={20} md={18}>
        {children}
      </Col>
      <Col xs={2} md={3} />
    </Row>
  );
};

export default Wrapper;
