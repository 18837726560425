import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import Cookies from "js-cookie";

interface UserData {
  user: string;
  name: string;
}

interface UserContextProps {
  userData: UserData | null;
  updateUserData: (data: UserData | null) => void;
}

const UserContext = createContext<UserContextProps>({
  userData: null,
  updateUserData: () => {},
});

type UserProviderProps = {
  children: React.ReactNode;
};
const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const mpguzo = Cookies.get("MPUGZO");
    if (mpguzo) {
      fetchUserData();
    }
  }, []);

  const fetchUserData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/userdata`, {
        withCredentials: true,
      })
      .then((res) => {
        updateUserData(res.data);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message, 7);
      });
  };

  const updateUserData = (data: UserData | null) => {
    setUserData(data);
  };

  return (
    <UserContext.Provider value={{ userData, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
