import { Button, Result } from "antd";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const ResultPage: React.FC = () => {
  const { code } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (code === "200") {
    return (
      <Result
        status="success"
        title="Hvala vam!"
        subTitle="Vaša prijava pomaže nam da poduzmemo odgovarajuće korake kako bismo riješili ovaj problem i očuvali našu zajednicu čistom i zdravom."
        extra={
          <Link to="/">
            <Button type="primary">Natrag na početnu</Button>
          </Link>
        }
      />
    );
  } else if (code === "500") {
    return (
      <Result
        status="500"
        title="Oops"
        subTitle="Nešto je pošlo po zlu, molimo pokušajte kasnije!"
        extra={
          <Link to="/">
            <Button type="primary">Natrag na početnu</Button>
          </Link>
        }
      />
    );
  } else {
    return null;
  }
};

export default ResultPage;
