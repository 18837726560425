import React, { useContext, useEffect, useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../components/user/UserContext";
import Cookies from "js-cookie";

const Login: React.FC = () => {
  const navigate = useNavigate();

  const { updateUserData } = useContext(UserContext);

  const [form] = Form.useForm();

  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Cookies.remove("MPUGZO");
    updateUserData(null);
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, values)
      .then(() => {
        navigate("/admin");
        setLoading(false);
      })
      .then(() => {
        fetchUserData();
      })
      .catch((error) => {
        message.error(error.response.data.message, 7);
        setLoading(false);
      });
  };

  const fetchUserData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/userdata`, {
        withCredentials: true,
      })
      .then((res) => {
        updateUserData(res.data);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message, 7);
      });
  };

  const validatePassword = (_: any, value: string) => {
    if (!value || value.length < 8) {
      return Promise.reject("Lozinka mora sadržavati najmanje 8 znakova.");
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        "Lozinka mora sadržavati barem jedno veliko slovo."
      );
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject("Lozinka mora sadržavati barem jedno malo slovo.");
    }
    if (!/\d/.test(value)) {
      return Promise.reject("Lozinka mora sadržavati barem jednu znamenku.");
    }
    return Promise.resolve();
  };

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <Card className="login-card" bordered={false}>
      <Form
        name="login"
        onFinish={onFinish}
        disabled={loading}
        form={form}
        autoComplete="off"
        scrollToFirstError
      >
        <Form.Item
          name="username"
          rules={[
            { required: true, message: "Unesite korisničko ime!", max: 40 },
          ]}
          hasFeedback
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Korisničko ime"
            maxLength={40}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Unesite lozinku!", max: 100 },
            {
              validator: validatePassword,
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Lozinka"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
            disabled={!submittable || loading}
          >
            Prijavi se
          </Button>
        </Form.Item>
        <Divider plain>ili</Divider>
        <div style={{ textAlign: "center" }}>
          <Link to="/registracija-korisnika">
            <Button
              type="primary"
              htmlType="button"
              style={{ background: "#00B96B" }}
            >
              Registriraj se
            </Button>
          </Link>
        </div>
      </Form>
    </Card>
  );
};

export default Login;
