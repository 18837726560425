import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  MenuProps,
  message,
  Result,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  UserOutlined,
  LogoutOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../assets/logo.svg";

import Home from "./home/Home";
import Registration from "./registration/Registration";
import ResultPage from "./result/ResultPage";
import Admin from "./admin/Admin";
import Login from "./login/Login";
import SignIn from "./signin/SignIn";
import axios from "axios";
import { UserContext } from "../components/user/UserContext";

const { Text } = Typography;
const { Footer, Header, Content } = Layout;

const headerStyle: React.CSSProperties = {
  color: "#fff",
  height: 64,
  paddingInline: 50,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const App: React.FC = () => {
  const navigate = useNavigate();

  const { userData, updateUserData } = useContext(UserContext);

  const [items, setItems] = useState<MenuProps["items"]>([]);

  useEffect(() => {
    if (userData) {
      setItems([
        {
          label: userData.name,
          icon: <UserOutlined />,
          key: 0,
        },
        {
          type: "divider",
        },
        {
          label: "Prijave odlagališta",
          icon: <GlobalOutlined />,
          key: 1,
          onClick: () => {
            navigate("/admin");
          },
        },
        {
          type: "divider",
        },
        {
          label: "Odjava",
          icon: <LogoutOutlined />,
          key: 2,
          onClick: handleLogout,
        },
      ]);
    }
  }, [userData]);

  const handleLogout = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/logout`)
      .then(() => {
        navigate("/prijava");
        updateUserData(null);
        message.success("Uspješno ste odjavljeni!");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message, 7);
      });
  };

  return (
    <Layout
      className="bg-color-white"
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Header style={headerStyle}>
        <Link className="flex" to="/">
          <img className="logo" height={44} src={logo} />
        </Link>
        {userData ? (
          <Dropdown menu={{ items }} placement="bottomRight">
            <Avatar
              icon={<UserOutlined />}
              style={{ backgroundColor: "#00B96B" }}
            />
          </Dropdown>
        ) : (
          <Link to="/prijava" style={{ color: "#ffffff" }}>
            <Avatar
              icon={<UserOutlined />}
              style={{ backgroundColor: "#969696" }}
            />
          </Link>
        )}
      </Header>
      <Content style={{ flex: 1, marginBottom: "4rem" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/prijava" element={<Login />} />
          <Route path="/registracija-korisnika" element={<SignIn />} />
          <Route path="/registracija" element={<Registration />} />
          <Route path="/status/:code" element={<ResultPage />} />
          <Route
            path="/admin"
            element={<ProtectedPage children={<Admin />} />}
          />
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Stranica koju ste tražili ne postoji."
                extra={
                  <Link to="/">
                    <Button type="primary">Natrag na početnu</Button>
                  </Link>
                }
              />
            }
          />
        </Routes>
      </Content>
      <Footer className="bg-color-white" style={{ flexShrink: 0 }}>
        <Text style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          © {new Date().getFullYear()} Ministarstvo prostornog uređenja,
          graditeljstva i zaštite okoliša Županije Zapadnohercegovačke. Sva
          prava pridržana.
        </Text>
      </Footer>
    </Layout>
  );
};

type Props = {
  children: React.ReactElement;
};
const ProtectedPage: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [cookie, setCookie] = useState<string | undefined>(undefined);

  useEffect(() => {
    const mpugzo = Cookies.get("MPUGZO");

    if (!mpugzo) {
      navigate("/prijava");
    }

    setCookie(mpugzo);
  }, []);

  if (cookie) {
    return children;
  }

  return null;
};

export default App;
