import React, { useEffect, useState } from "react";
import {
  Col,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import axios, { AxiosResponse } from "axios";
import {
  DeleteOutlined,
  EnvironmentOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Column } = Table;
const { Title } = Typography;

interface DataType {
  id: number;
  coordinates: string;
  place: string;
  description: string;
  person: string;
  mobile: string;
  control: number;
  created_at: Date;
}

const Admin: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataType[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const getRecords = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/records`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          navigate("/prijava");
          message.error(error?.response?.data?.message, 7);
        }
        message.error(error?.response?.data?.message, 7);
      });
  };

  useEffect(() => {
    getRecords();
  }, []);

  const handleOpenMap = (values: DataType) => {
    const url = `https://www.google.com/maps?q=${values.coordinates}&z=10`;
    window.open(url);
  };

  const handleDelete = (id: number) => {
    setConfirmLoading(true);

    axios
      .put(`${process.env.REACT_APP_API_URL}/records/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
        setConfirmLoading(false);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          navigate("/prijava");
          message.error(error?.response?.data?.message, 7);
        }
        setConfirmLoading(false);
      });
  };

  const handleDownload = async (id: number) => {
    try {
      const response: AxiosResponse<Blob> = await axios.get(
        `${process.env.REACT_APP_API_URL}/records/image/${id}`,
        {
          withCredentials: true,
          responseType: "arraybuffer",
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition.split(";")[1].split('"')[1].trim();

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = filename;
      a.target = "_self";
      a.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (id: number, status: number) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/records/${id}/${status}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          navigate("/prijava");
          message.error(error?.response?.data?.message, 7);
        }
      });
  };

  return (
    <Row>
      <Col xs={24} style={{ padding: "0 1rem" }}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row>
            <Col xs={24} className="text-center">
              <Title level={2}>EVIDENCIJA LOKACIJA ODBAČENOG OTPADA</Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Table
                loading={loading}
                dataSource={data}
                rowKey="id"
                locale={{ emptyText: "Nema podataka" }}
                scroll={{ x: 1000 }}
              >
                <Column
                  title="#"
                  dataIndex="index"
                  key="index"
                  render={(text, record, index) => index + 1}
                  width={60}
                />
                <Column title="Naziv lokacije" dataIndex="place" key="place" />
                <Column
                  title="Opis lokacije i otpada"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Ime i prezime" dataIndex="person" key="person" />
                <Column title="Broj mobitela" dataIndex="mobile" key="mobile" />
                <Column
                  title="Datum prijave"
                  dataIndex="created_at"
                  key="created_at"
                  render={(_: any, record: DataType) => {
                    const date = new Date(record.created_at);
                    return date.toLocaleString();
                  }}
                />
                <Column
                  title="Status"
                  dataIndex="control"
                  key="control"
                  render={(_: any, record: DataType) => {
                    return (
                      <Select
                        value={record.control}
                        onChange={(value) =>
                          handleStatusChange(record.id, value)
                        }
                        bordered={false}
                        options={[
                          {
                            value: 0,
                            label: "Odbačena prijava",
                          },
                          { value: 1, label: "Na čekanju" },
                          { value: 2, label: "U provjeri" },
                          { value: 3, label: "Uspješno sanirano" },
                          { value: 4, label: "Netočna prijava" },
                        ]}
                      />
                    );
                  }}
                />
                <Column
                  title="Akcija"
                  key="action"
                  fixed="right"
                  render={(_: any, record: DataType) => (
                    <Space size="middle">
                      <Tooltip title={record.coordinates}>
                        <EnvironmentOutlined
                          onClick={() => handleOpenMap(record)}
                        />
                      </Tooltip>
                      <Tooltip title="Preuzmi sliku">
                        <DownloadOutlined
                          onClick={() => handleDownload(record.id)}
                        />
                      </Tooltip>
                      <Popconfirm
                        title="Izbriši"
                        description="Da li ste sigurni da želite obrisati prijavu?"
                        okButtonProps={{ loading: confirmLoading }}
                        okText="Da"
                        cancelText="Ne"
                        onConfirm={() => handleDelete(record.id)}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </Space>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default Admin;
