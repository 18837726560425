import React, { useContext, useEffect, useState } from "react";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import isEmail from "validator/es/lib/isEmail";
import Cookies from "js-cookie";
import { UserContext } from "../../components/user/UserContext";

const SignIn: React.FC = () => {
  const navigate = useNavigate();

  const { updateUserData } = useContext(UserContext);

  const [form] = Form.useForm();

  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Cookies.remove("MPUGZO");
    updateUserData(null);
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/sign-in`, values)
      .then(() => {
        navigate("/prijava");
        message.success(
          "Uspješno ste se registrirali! Kontaktirajte administraciju kako bi Vam aktivirali račun!"
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validatePassword = (_: any, value: string) => {
    if (!value || value.length < 8) {
      return Promise.reject("Lozinka mora sadržavati najmanje 8 znakova.");
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        "Lozinka mora sadržavati barem jedno veliko slovo."
      );
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject("Lozinka mora sadržavati barem jedno malo slovo.");
    }
    if (!/\d/.test(value)) {
      return Promise.reject("Lozinka mora sadržavati barem jednu znamenku.");
    }
    return Promise.resolve();
  };

  const validateEmail = (_: any, value: string) => {
    if (!value || isEmail(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Neispravan format e-maila");
  };

  // Watch all values
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <Card className="signin-card" bordered={false}>
      <Form
        name="sign-in"
        onFinish={onFinish}
        disabled={loading}
        form={form}
        autoComplete="off"
        scrollToFirstError
      >
        <Form.Item
          name="firstname"
          rules={[{ required: true, message: "Unesite ime!", max: 40 }]}
          hasFeedback
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Ime"
            maxLength={40}
          />
        </Form.Item>
        <Form.Item
          name="lastname"
          rules={[{ required: true, message: "Unesite prezime!", max: 40 }]}
          hasFeedback
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Prezime"
            maxLength={40}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Unesite e-mail adresu!" },
            {
              validator: validateEmail,
            },
          ]}
          hasFeedback
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="E-Mail"
          />
        </Form.Item>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Unesite željeno korisničko ime!",
              max: 40,
            },
          ]}
          hasFeedback
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Korisničko ime"
            maxLength={40}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Unesite lozinku", max: 100 },
            {
              validator: validatePassword,
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Lozinka"
            maxLength={100}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="signin-form-button"
            style={{ background: "#00B96B" }}
            disabled={!submittable || loading}
          >
            Registriraj se
          </Button>
        </Form.Item>
        <Divider plain>ili</Divider>
        <div style={{ textAlign: "center" }}>
          <Link to="/prijava">Već imate račun?</Link>
        </div>
      </Form>
    </Card>
  );
};

export default SignIn;
