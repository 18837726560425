import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { FormInstance, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";

const Location = ({ form }: { form: FormInstance }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLngLiteral | null>(null);

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const mapOptions = {
    region: "BA",
    zoom: 12,
    mapTypeId: "hybrid",
    center: { lat: 43.47091753326119, lng: 17.332083022677228 },
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
  });

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const { latLng } = event;

    if (latLng) {
      const lat = latLng.lat();
      const lng = latLng.lng();

      form.setFieldValue("coordinates", `${lat}, ${lng}`);
      setMarkerPosition({ lat, lng });
    }
  };

  useEffect(() => {
    if (map && markerPosition) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(markerPosition);
      map.fitBounds(bounds);
    }
  }, [map, markerPosition]);

  return (
    <React.Fragment>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={mapOptions}
          onClick={handleMapClick}
        >
          {markerPosition && <Marker position={markerPosition} />}
        </GoogleMap>
      ) : (
        <div
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </React.Fragment>
  );
};

export default Location;
