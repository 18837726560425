import { AimOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import { parsePhoneNumber } from "awesome-phonenumber";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SubmitButton from "./SubmitButton";

const { TextArea } = Input;
const { Option } = Select;

type Props = {
  form: FormInstance;
};
const FormWrapper: React.FC<Props> = ({ form }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    onRemove: () => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const allowedFormats = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/heic",
      ];
      const isImage = allowedFormats.includes(file.type);
      if (!isImage) {
        message.error("Molimo odaberite samo slikovne datoteke!", 7);
        return false;
      }

      setFileList([file]);

      return false;
    },
    fileList,
  };

  const onFinish = (values: any) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file as RcFile);
    });
    formData.append("coordinates", values.coordinates);
    formData.append("place", values.place);
    formData.append("description", values.description);
    formData.append("person", values.person);
    formData.append("prefix", values.prefix);
    formData.append("mobile", values.mobile);

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/prijavi`, formData)
      .then((res) => {
        setLoading(false);
        message.success(res.data.message);
        navigate("/status/200");
      })
      .catch(({ response }) => {
        if (response.status === 500) {
          navigate("/status/500");
        } else if (response.data.message && response.status === 500) {
          message.error(response.data.message, 5);
          navigate("/status/500");
        }
        setLoading(false);
      });
  };

  const validatePhoneNumber = (_: any, value: string) => {
    if (value) {
      const prefix = form.getFieldValue("prefix");
      console.log(prefix);
      const pn = parsePhoneNumber(value, {
        regionCode: prefix === "+387" ? "BA" : "HR",
      });
      console.log(pn);
      if (!pn.valid) {
        return Promise.reject("Neispravan broj telefona!");
      }
    }
    return Promise.resolve();
  };

  const handleSelectChange = () => {
    form.resetFields(["mobile"]);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select onChange={handleSelectChange} style={{ width: 80 }}>
        <Option value="+387">+387</Option>
        <Option value="+385">+385</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form
      name="register"
      layout="vertical"
      form={form}
      initialValues={{ prefix: "+387" }}
      onFinish={onFinish}
      disabled={loading}
      scrollToFirstError
      autoComplete="off"
    >
      <Row gutter={48}>
        <Col xs={24} xl={12}>
          <Form.Item
            name="coordinates"
            label="GPS koordinate"
            rules={[
              {
                required: true,
                message: "Odaberi lokaciju na mapi!",
              },
            ]}
            tooltip="Odaberi lokaciju na mapi!"
            hasFeedback
          >
            <Input readOnly maxLength={50} addonBefore={<AimOutlined />} />
          </Form.Item>
          <Form.Item
            name="place"
            label="Naziv lokacije"
            rules={[
              {
                required: true,
                message: "Unesi naziv lokacije!",
              },
            ]}
            hasFeedback
          >
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Opis lokacije i otpada"
            rules={[
              {
                required: true,
                message: "Unesi opis lokacije i otpada!",
              },
            ]}
            hasFeedback
          >
            <TextArea rows={4} maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="upload"
            label="Priložite fotografiju"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
            rules={[
              {
                required: true,
                message: "Priloži fotografiju s mjesta!",
              },
            ]}
          >
            <Upload listType="picture" maxCount={1} {...props}>
              <Button icon={<UploadOutlined />}>Odaberi</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name="person" label="Ime i prezime" hasFeedback>
            <Input maxLength={50} />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="Broj mobitela"
            rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
            hasFeedback
          >
            <Input
              addonBefore={prefixSelector}
              style={{ width: "100%" }}
              maxLength={16}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Space>
              <Link to="/">
                <Button type="default">Nazad</Button>
              </Link>
            </Space>
          </div>
          <div>
            <Space>
              <Button htmlType="reset">Očisti</Button>
              <SubmitButton form={form} loading={loading} />
            </Space>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};

export default FormWrapper;
