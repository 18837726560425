import { Col, Form, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";

import Wrapper from "../../components/wrapper/Wrapper";
import Location from "../location/Location";
import FormWrapper from "./FormWrapper";

const { Title } = Typography;

const Registration: React.FC = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row>
          <Col xs={24} className="text-center">
            <Title level={2}>PRIJAVITE OTPAD</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Location form={form} />
          </Col>
          <Col xs={24}>
            <Col>
              <Title level={3}>Prijava lokacije odbačenog otpada</Title>
            </Col>
            <FormWrapper form={form} />
          </Col>
        </Row>
      </Space>
    </Wrapper>
  );
};

export default Registration;
